<template>
  <b-container fluid>
    <google-calendars></google-calendars>
    <college-api-credentials-colegium></college-api-credentials-colegium>
  </b-container>
</template>

<script>
import GoogleCalendars from "./GoogleCalendars";
import CollegeApiCredentialsColegium from "./Colegium";
export default {
  components: {
    GoogleCalendars,
    CollegeApiCredentialsColegium,
  },
};
</script>

<style></style>
