<template>
  <section>
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t("colleges.colegium.index") }}</h4>
      </template>
      <template v-slot:headerAction>
        <b-button
          v-if="collageAuthUser"
          variant="outline-primary"
          class="mr-2"
          @click="sendSyncCollegeStudentsColegium"
          >{{ $t("user-college.colegium") }}</b-button
        >
        <b-button
          :disabled="collegeApiCredentials.length >= 1"
          variant="primary"
          @click="$refs.modalApiCredentials.show()"
          >{{ $t("app.new") }}</b-button
        >
      </template>
      <template v-slot:body>
        <div class="table-responsive">
          <b-table
            class="table mb-0 table-borderless"
            :items="collegeApiCredentials"
            :fields="columns"
            :busy="
              !Array.isArray(collegeApiCredentials) ||
              collegeApiCredentials.length === 0
                ? true
                : false || apiCredentialsLogin
            "
          >
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <div v-if="apiCredentialsLogin">
                  <b-spinner type="grow" class="align-middle"></b-spinner>
                  <strong>{{ $t("app.loading") }}...</strong>
                </div>
                <span class="h5" v-else>{{ $t("app.not-found") }}</span>
              </div>
            </template>

            <template v-slot:cell(action)="data">
              <b-button
                variant=" iq-bg-warning rounded"
                v-b-tooltip.hover
                title="Editar"
                class="mr-1"
                size="sm"
                @click="$refs.modalApiCredentials.show(data.item)"
                ><i class="ri-ball-pen-fill ml-1"></i
              ></b-button>
              <b-button
                @click="deleteItem(data.item.id)"
                v-b-tooltip.hover
                title="Eliminar"
                variant=" iq-bg-danger rounded"
                size="sm"
                ><i class="ri-delete-bin-line ml-1"></i
              ></b-button>
            </template>
          </b-table>
        </div>
      </template>
    </iq-card>
    <modal-form-api-credentials
      @refresh="getData()"
      ref="modalApiCredentials"
    />
  </section>
</template>
<script>
import { core } from "@/config/pluginInit";
import modalFormApiCredentials from "./modalForm.vue";
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";

export default {
  name: "ColegiumApiCredentials",
  components: {
    modalFormApiCredentials,
  },
  mounted() {
    core.index();
    this.getData();
  },
  methods: {
    ...mapActions({
      fetchCollegeApiCredentials: "fetchCollegeApiCredentials",
      deleteCollegeApiCredentials: "deleteCollegeApiCredentials",
      syncStudentsCollegeColegium: "syncStudentsCollegeColegium",
    }),
    getData() {
      this.fetchCollegeApiCredentials();
    },
    deleteItem(id) {
      this.$bvModal
        .msgBoxConfirm(this.$t("app.body-confirm-delete"), {
          title: this.$t("app.title-confirm-delete"),
          size: "sm",
          buttonSize: "sm",
          okVariant: "success rounded",
          cancelVariant: "outline-danger rounded",
          okTitle: this.$t("app.yes"),
          cancelTitle: this.$t("app.not"),
          headerClass: "text-center",
          bodyClass: "text-center text-danger",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then(async (value) => {
          if (value) {
            const resp = await this.deleteCollegeApiCredentials(id);
            this.getData();
            if (resp?.status == 200) {
              core.showSnackbar("success", resp.data.message);
            }
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    async sendSyncCollegeStudentsColegium() {
      const resp = await this.syncStudentsCollegeColegium();
      core.showSnackbar("success", resp.data.message);
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      collegeApiCredentials: "collegeApiCredentials",
      apiCredentialsLogin: "apiCredentialsLogin",
      collageAuthUser: "collageAuthUser",
    }),
    columns() {
      return [
        {
          label: this.$t("colleges.colegium.username"),
          key: "username",
          class: "text-center",
        },
        {
          label: this.$t("colleges.colegium.college_code"),
          key: "college_code",
          class: "text-center",
        },
        { label: this.$t("app.action"), key: "action", class: "text-center" },
      ];
    },
  },
};
</script>
