<template>
  <section>
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t("colleges.google-calendar.index") }}</h4>
      </template>
      <template v-slot:headerAction>
        <b-button variant="primary" @click="$refs.modalGoogleCalendar.show()">{{
          $t("app.new")
        }}</b-button>
      </template>
      <template v-slot:body>
        <div class="table-responsive">
          <b-table
            class="table mb-0 table-borderless"
            :items="getCalendars"
            :fields="columns"
            :busy="
              !Array.isArray(getCalendars) || getCalendars.length === 0
                ? true
                : false || calendarLoading || calendarLoadingSync
            "
          >
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <div v-if="calendarLoading">
                  <b-spinner type="grow" class="align-middle"></b-spinner>
                  <strong>{{ $t("app.loading") }}...</strong>
                </div>
                <div v-else-if="calendarLoadingSync">
                  <b-spinner type="grow" class="align-middle"></b-spinner>
                  <span>{{ $t("app.sync") }}...</span>
                </div>
                <span class="h5" v-else>{{ $t("app.not-found") }}</span>
              </div>
            </template>

            <template v-slot:cell(action)="data">
              <b-button
                variant=" iq-bg-primary rounded"
                v-b-tooltip.hover
                title="Sincronizar eventos"
                class="mr-1"
                size="sm"
                @click="syncGoogleCalendarEvents(data.item.id)"
                ><i class="ri-repeat-line ml-1"></i
              ></b-button>
              <b-button
                variant=" iq-bg-warning rounded"
                v-b-tooltip.hover
                title="Editar"
                class="mr-1"
                size="sm"
                @click="$refs.modalGoogleCalendar.show(data.item)"
                ><i class="ri-ball-pen-fill ml-1"></i
              ></b-button>
              <b-button
                @click="deleteItem(data.item.id)"
                v-b-tooltip.hover
                title="Eliminar"
                variant=" iq-bg-danger rounded"
                size="sm"
                ><i class="ri-delete-bin-line ml-1"></i
              ></b-button>
            </template>
          </b-table>
        </div>
      </template>
    </iq-card>
    <modal-form-google-calendar
      @refresh="getData()"
      ref="modalGoogleCalendar"
    />
  </section>
</template>
<script>
import { core } from "@/config/pluginInit";
import modalFormGoogleCalendar from "./modalForm.vue";
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";

export default {
  name: "GoogleCalendars",
  components: {
    modalFormGoogleCalendar,
  },
  mounted() {
    core.index();
    this.getData();
  },
  methods: {
    ...mapActions({
      fetchCollegeGoogleCalendars: "fetchCollegeGoogleCalendars",
      deleteCollegeGoogleCalendar: "deleteCollegeGoogleCalendar",
      syncCollegeGoogleCalendarEvents: "syncCollegeGoogleCalendarEvents",
    }),
    async syncGoogleCalendarEvents(id) {
      const resp = await this.syncCollegeGoogleCalendarEvents({ id });
      if (resp.status === 200) {
        core.showSnackbar("success", resp.data.message);
      }
    },
    getData() {
      this.fetchCollegeGoogleCalendars();
    },
    deleteItem(id) {
      this.$bvModal
        .msgBoxConfirm(this.$t("app.body-confirm-delete"), {
          title: this.$t("app.title-confirm-delete"),
          size: "sm",
          buttonSize: "sm",
          okVariant: "success rounded",
          cancelVariant: "outline-danger rounded",
          okTitle: this.$t("app.yes"),
          cancelTitle: this.$t("app.not"),
          headerClass: "text-center",
          bodyClass: "text-center text-danger",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then(async (value) => {
          if (value) {
            const resp = await this.deleteCollegeGoogleCalendar(id);
            this.getData();
            if (resp?.status == 200) {
              core.showSnackbar("success", resp.data.message);
            }
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      getCalendars: "collegeGoogleCalendars",
      calendarLoading: "calendarLoading",
      calendarLoadingSync: "calendarLoadingSync",
    }),
    columns() {
      return [
        {
          label: this.$t("colleges.google-calendar.name"),
          key: "name",
          class: "text-center",
        },
        {
          label: this.$t("colleges.google-calendar.calendar_id"),
          key: "calendar_id",
          class: "text-center",
        },
        { label: this.$t("app.action"), key: "action", class: "text-center" },
      ];
    },
  },
};
</script>
